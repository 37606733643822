import useAuth from "@/redux/dispatch/useAuth";
import { Button } from "@/components/ui/button";
import React, { useState, useEffect } from "react";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { PiShoppingCartLight, PiUserCircleFill } from "react-icons/pi";
import { GiClothes } from "react-icons/gi";
import { Bell } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuRadioGroup,
} from "@/components/ui/dropdown-menu";

import NotificationCard from "@/components/Notification/NotificationCard";
import { ClearAllOutlined } from "@mui/icons-material";

import { isEqual, replace } from "lodash";

const Header = ({ toggleSidebar, isSidebarOpen }) => {
  const { isAuthenticated, socket, auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [notifications, setNotifications] = useState([]);
  const [stateUrl, setStateUrl] = useState();

  const markAsRead = (notificationId) => {
    socket.emit("markNotificationAsRead", [notificationId]);

    setNotifications((prev) =>
      prev.filter((notification) => notification._id !== notificationId),
    );
  };

  useEffect(() => {
    const loc=window.location.href;
    const ver= loc.indexOf('diffrent');
    if(ver!==-1){
      setStateUrl(true);
    }
    if (socket) {
      socket.emit("joinNotification", auth.user.id);

      socket.on("receiveNotifications", (notification) => {
        setNotifications((prevNotifications) => {
          // Check if the new notification is already in the array
          if (!isEqual(notification, prevNotifications)) {
            return [...notification, ...prevNotifications];
          }
          return prevNotifications;
        });
      });
    }

    return () => {
      if (socket) {
        socket.off("receiveNotifications");
      }
    };
  }, [socket]);

  return (
    <div className={`relative z-40 flex w-full items-center justify-between border-b border-gray-300 bg-background p-2 shadow-md ${stateUrl && "bg-lime-500 text-white"}`}>
      
      {!stateUrl && <div
        className="cursor-pointer text-xl font-bold"
        onClick={() => navigate("/")}
      >
        <img
          src="/images/full_logo.png"
          alt="logo"
          className="h-8 -pl-2 sm:h-10 lg:h-12"
        />
      </div>}
      <div className={`${stateUrl && "justify-between w-full" } flex items-center gap-2`}>
        {
          // <Button className={`flex gap-2 items-center h-10 ${!stateUrl && "border border-black" }`} 
          // variant={`${!stateUrl ? "outline": "ghost" }`}
          // onClick={() =>
          //   navigate(
          //     "/diffrent/home",
          //     {
          //       state: { from: location.pathname },
          //     },
          //     { replace: true },
          //   )
          // }
          // >
            
          // <GiClothes className="text-xl -ml-2"/> 
          // <div className={`flex items-center gap-1 -mr-1 ${stateUrl ? "":"flex-col"}`}>
            
          //   <div>OWNLY</div> 
          //   <div className="text-[0.6rem] -mt-1 font-normal">fashions</div>
          // </div>
          // </Button>
          <img src="/images/nobg.png" alt=""
          className="h-8 bg-lime-500 rounded-md sm:h-10 lg:h-12"
          onClick={() =>
        navigate(
          "/diffrent/subscription",
          {
            state: { from: location.pathname },
          },
          { replace: true },
        )
      }
        />
        }
        <div className="flex gap-2 items-center">
        {isAuthenticated && !stateUrl &&(
          <DropdownMenu>
            <DropdownMenuTrigger className="m-1">
              <Button
                key="notification"
                size="icon"
                variant="outline"
                className="relative"
              >
                {notifications.length > 0 && (
                  <div className="absolute -right-1 -top-1 -mr-1 -mt-1 flex h-4 w-4 items-center justify-center rounded-full bg-primary text-xs text-white">
                    {notifications.length}
                  </div>
                )}
                <Bell className="text-xl" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-4 h-[60vh] min-h-[500px] w-[26vw] min-w-[350px] overflow-y-scroll">
              <div className="flex items-center justify-between px-4 py-2">
                <DropdownMenuLabel className="text-center text-lg font-bold">
                  Notifications
                </DropdownMenuLabel>
                <Button
                  size="icon"
                  variant="outline"
                  onClick={() => {
                    socket.emit(
                      "markNotificationAsRead",
                      notifications.map((notification) => notification._id),
                    );
                    setNotifications([]);
                  }}
                >
                  <ClearAllOutlined />
                </Button>
              </div>
              <DropdownMenuSeparator className="my-1 border-t border-primary" />
              <div className="flex flex-col gap-1">
                {notifications?.length > 0 &&
                  notifications?.map((notification) => (
                    <NotificationCard
                      key={notification?.id}
                      notification={notification}
                      markAsRead={markAsRead}
                    />
                  ))}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        )}

        {!isAuthenticated && (
          <Button
            colour={`${stateUrl && "lime"}`}
            onClick={() =>{stateUrl ? 
              navigate(
                "/diffrent/login",
                {
                  state: { from: location.pathname },
                },
                { replace: true },
              )
            :
              navigate(
                "/login",
                {
                  state: { from: location.pathname },
                },
                { replace: true },
              )
            }
            }
          >
            Login
          </Button>
        )}
        {!stateUrl &&
        <Button
        size="icon"
        variant="secondary"
        className="flex cursor-pointer items-center justify-center sm:hidden"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? (
          <IoCloseOutline className="text-2xl" />
        ) : (
          <IoMenuOutline className="text-2xl" />
        )}
      </Button>}
      {/* {stateUrl && 
        <Button variant="ghost" className="font-bold" size="icon" colour="" onClick={()=> !auth.user ? navigate("/diffrent/login") : navigate("/diffrent/cart")}>
            <PiShoppingCartLight size={22} />
        </Button>
      } */}
      {stateUrl && 
          <DropdownMenu>
            <DropdownMenuTrigger className="pr-2 ">
              
                <PiUserCircleFill size={28} />
              
            </DropdownMenuTrigger>
            <DropdownMenuContent className="  min-h-[100px] min-w-[200px] bg-lime-50">
              <div className="flex  justify-between ">
                <DropdownMenuLabel className="text-center flex gap-4 bg-lime-50 text-lime-800 items-center text-lg font-bold">
                    <PiUserCircleFill className="text-4xl" />
                    {auth.user ? 
                    <div className="flex flex-col  items-start">
                      <span >{auth.user.phone}</span> 
                      <span className="text-sm font-normal"> {auth.user.email}</span> 
                    </div> : "Login"}
                </DropdownMenuLabel>
                
              </div>
            <DropdownMenuSeparator className=" border-t bg-lime-50" />
              <div className="flex flex-col ">
              {/* <Button colour="gr_lime_emerald_lite" onClick={()=>navigate("/diffrent/home")} >Home</Button> */}
              {/* <Button colour="gr_lime_emerald_lite" onClick={()=>navigate("/diffrent/how")} >How it works</Button> */}
              <Button colour="gr_lime_emerald_lite" onClick={()=>navigate("/diffrent/subscription")} >Subscriptions</Button>
              {/* <Button colour="gr_lime_emerald_lite" onClick={()=>!auth.user ? navigate("/diffrent/login") : navigate("/diffrent/orders")} >Orders</Button> */}
              {/* <Button colour="gr_lime_emerald_lite" onClick={()=>!auth.user ? navigate("/diffrent/login") : navigate("/diffrent/closet")}>Wishlist</Button> */}
              {/* <Button colour="gr_lime_emerald_lite" onClick={()=>!auth.user ? navigate("/diffrent/login") : navigate("/diffrent/cart")}>Cart</Button> */}
              {!auth.user &&
              <Button colour="gr_lime_emerald" onClick={()=>navigate("/diffrent/login")}>Login</Button>}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
      }
      </div>
      </div>
    </div>
  );
};

export default Header;
