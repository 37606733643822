import LoadingSpinner from "@/components/ui/LoadingSpinner";
import React, { Suspense, lazy } from "react";

// Lazy load the auth components
const Login = lazy(() => import("@/pages/Auth/Login"));
const Ownlylogin = lazy(() => import("@/pages/Ownly/Login"));
const UpdateMobile = lazy(() => import("@/pages/Auth/UpdateMobile"));
const SignUp = lazy(() => import("@/pages/Auth/Signup"));
const ForgotPassword = lazy(() => import("@/pages/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("@/pages/Auth/ResetPassword"));
const VerifyUser = lazy(() => import("@/pages/Auth/VerifyUser"));
const AdminVerify = lazy(() => import("@/pages/Auth/AdminVerify"));
const Verify2FAUser = lazy(() => import("@/pages/Auth/TwoFA"));

const AuthRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/diffrent/login", element: <Ownlylogin /> },
  { path: "/updatemobile", element: <UpdateMobile /> },
  { path: "/signup", element: <SignUp /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/verify-user", element: <VerifyUser /> },
  { path: "/verify-admin", element: <AdminVerify /> },
  { path: "/2fa", element: <Verify2FAUser /> },
];

export default AuthRoutes;
