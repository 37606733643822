import React, { lazy } from "react";

// Lazy load the home and general pages
const Home = lazy(() => import("@/pages/Home/Home"));
const NewHome = lazy(() => import("@/pages/Home/NewHome"));
const Consultations = lazy(() => import("@/pages/Home/Consultations"));
const Assets = lazy(() => import("@/pages/Home/Assets"));
const Support = lazy(() => import("@/pages/Support"));
const About = lazy(() => import("@/pages/About"));
const Tutorials = lazy(() => import("@/pages/Tutorials"));
const FAQ = lazy(() => import("@/pages/FAQs/FAQ"));
const TermsAndPolicies = lazy(() => import("@/pages/Terms&Polices"));
const UnauthCollabDetails = lazy(() => import("@/pages/Collaboration/UnauthCollabDetails"));
const PricePage = lazy(() => import("@/pages/Terms&Polices/Price"));
const PrivacyPolicies = lazy(
  () => import("@/pages/Terms&Polices/PrivacyPolicies"),
);
const RefundPolicy = lazy(() => import("@/pages/Terms&Polices/Refund"));
const TermsConditions = lazy(
  () => import("@/pages/Terms&Polices/TermsCondition"),
);
const FAQHome = lazy(() => import("@/pages/FAQs/FAQHome"));
const CommunityGuidelines = lazy(
  () => import("@/pages/Terms&Polices/CommunityGuidelines"),
);
const BiddingHome = lazy(() => import("@/pages/Home/BiddingHome"));
const UnauthorisedBiddingHome = lazy(
  () => import("@/pages/Home/UnauthorisedBiddingHome"),
);
const AuctionMeta = lazy(
  () => import("@/pages/Home/AuctionMeta"),
);
const Subscription = lazy(() => import("@/pages/Subscription/SubscriptionMain"));
const OwnlyMain = lazy(() => import("@/pages/Ownly/OwnlyMain"));
const OwnlyHow = lazy(() => import("@/pages/Ownly/HowItWorks"));

const publicRoutes = [
  { path: "/", element: <NewHome /> },
  { path: "/consultations", element: <Consultations /> },
  { path: "/home", element: <Home /> },
  { path: "/assets", element: <Assets /> },
  { path: "/support", element: <Support /> },
  { path: "/about", element: <About /> },
  { path: "/support/tutorials", element: <Tutorials /> },
  { path: "/support/faq-home", element: <FAQHome /> },
  { path: "/support/faq/:category", element: <FAQ /> },
  { path: "/terms-and-policies", element: <TermsAndPolicies /> },
  { path: "/terms-and-policies/pricing", element: <PricePage /> },
  { path: "/terms-and-policies/refund-policy", element: <RefundPolicy /> },
  { path: "/terms-and-policies/privacy-policy", element: <PrivacyPolicies /> },
  { path: "/unauth-collab/:id", element: <UnauthCollabDetails /> },
  {
    path: "/terms-and-policies/terms-and-conditions",
    element: <TermsConditions />,
  },
  {
    path: "/terms-and-policies/CommunityGuidelines",
    element: <CommunityGuidelines />,
  },
  { path: "/unauthhome-bidding/:id", element: <UnauthorisedBiddingHome /> },
  { path: "/diffrent/subscription", element: <Subscription /> },
  { path: "/diffrent/home", element: <OwnlyMain /> },
  { path: "/diffrent/how", element: <OwnlyHow /> },
  { path: "/auction/:id", element: <AuctionMeta /> },
];

export default publicRoutes;
