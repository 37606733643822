import useAuth from '@/redux/dispatch/useAuth';
import React, { useEffect, useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import LoadingSpinner from "@/components/ui/LoadingSpinner";

function PersistentLogin() {
  const { refershToken, auth } = useAuth();

  useLayoutEffect(() => {
    refershToken();
  }, []);



  if (auth.loading) return <LoadingSpinner />;

  return <>{<Outlet />}</>;
}

export default PersistentLogin;
