import React, { lazy } from "react";

// Lazy load the affiliation center components
const Cart = lazy(
  () => import("@/pages/Ownly/Cart"),
);
const Closet = lazy(
  () => import("@/pages/Ownly/Closet"),
);
const Orders = lazy(
  () => import("@/pages/Ownly/Orders"),
);

const affiliationRoutes = [
  { path: "/diffrent/cart", element: <Cart /> },
  { path: "/diffrent/closet", element: <Closet /> },
  { path: "/diffrent/orders", element: <Orders /> },
];

export default affiliationRoutes;
